import React from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { loadFolders } from "@frontend/api/dashboard.service";
import { getUserGroups } from "@frontend/api/user-groups.service";
import { TeamspaceModal } from "@frontend/containers/modals/teamspace-modal";
import { getFolderPath } from "@frontend/routes";

import { IconButton } from "@components/button";
import { DropdownActionIconButton } from "@components/dropdown";
import { FolderAddLineIcon, MoreFillIcon } from "@components/icons";
import { LoadingIcon } from "@components/loading-icon";
import { Tabs } from "@components/tabs/tabs";
import { UserGroup } from "@components/user-group/user-group";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { useQuery } from "@core/hooks/use-query";
import { ModalType } from "@core/interfaces/modal-type";
import { User } from "@core/interfaces/user";
import { Folder } from "@core/state/dashboard/dashboard.store";
import { useUserGroups } from "@core/state/user-groups/user-groups.hooks";
import { FileType, ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

import { CreateFolderModal } from "../../dashboard-nav/create-folder-modal";
import { PrivateFolderMenu } from "../../dashboard-sidebar/drives/private-folder-menu";
import { TeamspaceMenu } from "../../dashboard-sidebar/drives/teamspace-menu";
import { DashboardUploadButton } from "../../dashboard-upload/dashboard-upload";

import { DashboardMediaSearch } from "./media-search";
import { ViewTypeDropdown } from "./view-type-dropdown";

type DashboardHeaderProps = {
  title: string;
  folder?: Folder;
  isUpdating?: boolean;
  includeSearch?: boolean;
  includeMediaControls?: boolean;
  includeUploadButton?: boolean;
  includeCreateFolderButton?: boolean;
  includeFolderOptions?: boolean;
  includeFileType?: boolean;
  crumbs?: Folder[];
  onCreateFolder?: () => void;
};
export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  folder,
  isUpdating,
  includeSearch,
  includeMediaControls,
  includeUploadButton,
  includeCreateFolderButton,
  includeFolderOptions,
  includeFileType,
  crumbs
}) => {
  const [isCreatingFolder, setIsCreatingFolder] = React.useState<boolean>(false);
  const [menuIsExpanded, setMenuExpanded] = React.useState<boolean>(false);
  const [isSavingFolder, setSavingFolder] = React.useState<boolean>(false);
  const { privateDrive } = useDashboard();
  const { currentAccount, user, accountTeam } = useAccounts();
  const { permissions: folderPermissions } = useRolePermissions(
    currentAccount?.role,
    folder?.isPublic ? ResourceType.Teamspace : ResourceType.PrivateFolder
  );
  const { permissions: mediaPermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);
  const { queryParams, updateQueryParams } = useQuery();

  const canCreateFolder = folderPermissions[ResourceAction.Create];
  const canUploadMedia = mediaPermissions[ResourceAction.Create];
  const navigate = useNavigate();

  const handleCreateNewFolder = async () => {
    const [showCreateModal, hideCreateModal] = useModal(ModalType.CreateFolder);

    // the parent of the created folder
    const parentFolder = folder ?? privateDrive;

    if (!parentFolder) return;

    const inRootWorkspaceDrive = folder?.isPublic && !parentFolder?.parentId;

    if (inRootWorkspaceDrive) {
      // If we are in the root workspace drive, open the teamspace modal instead
      const [showCreateModal, hideCreateModal] = useModal(ModalType.CreateFolder);

      showCreateModal(<TeamspaceModal closeModal={hideCreateModal} />);
    } else {
      // Else create a new folder
      const handleBeforeSave = () => setIsCreatingFolder(true);
      const handleCompleteSave = async () => {
        await loadFolders();
        setIsCreatingFolder(false);

        // If we are on "all media" or "shared with me", the new folder will get
        // automatically added to the private directory. In this case we will
        // switch the active folder to the private drive.
        if (!folder && privateDrive) {
          navigate(getFolderPath(privateDrive.id));
        }
      };

      showCreateModal(
        <CreateFolderModal
          isPublic={parentFolder.isPublic}
          parentId={parentFolder.id ?? undefined}
          hideModal={hideCreateModal}
          onBeforeSave={handleBeforeSave}
          onCompleteSave={handleCompleteSave}
        />
      );
    }
  };

  React.useEffect(() => {
    getUserGroups();
  }, []);

  const userGroups = useUserGroups() ?? [];
  const members = React.useMemo<Pick<User, "id" | "name" | "email">[] | null>(() => {
    const { isPublic, groupId } = folder ?? {};
    if (!isPublic) return null;
    if (groupId) {
      return userGroups.find((userGroup) => userGroup.id === groupId)?.members ?? null;
    }
    return accountTeam?.members ?? null;
  }, [userGroups, folder?.groupId, folder?.isPublic, accountTeam?.members]);

  const { permissions: userGroupPermissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroup);
  const canUpdateFolder = userGroupPermissions[ResourceAction.Update];

  const handleOpenTeamspaceManager = () => {
    if (!canUpdateFolder) return;
    const [showTeamspaceModal, hideTeamspaceModal] = useModal(ModalType.ManageTeamspace);
    showTeamspaceModal(<TeamspaceModal closeModal={hideTeamspaceModal} folder={folder} />);
  };

  return (
    <div className="tw-mb-6 tw-flex tw-flex-col tw-border-b tw-border-b-neutral-200">
      <div className="tw-flex tw-flex-col">
        <div className="tw-group tw-mb-6 tw-flex tw-min-h-[56px] tw-items-center tw-justify-between tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-[4.5px] tw-overflow-hidden">
            {crumbs && <Breadcrumbs crumbs={crumbs ?? []} />}
            <div className="tw-flex tw-items-center tw-gap-2">
              <h1 className="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden tw-text-ellipsis tw-text-h5 tw-font-semibold">
                {title}
              </h1>

              {includeFolderOptions && folder && (
                <div
                  className={classNames("tw-opacity-0 group-hover:tw-opacity-100", {
                    "!tw-opacity-100": menuIsExpanded || isSavingFolder
                  })}
                >
                  {Boolean(folder.isPublic) ? (
                    <TeamspaceMenu
                      folder={folder}
                      onChange={loadFolders}
                      onSaving={setSavingFolder}
                      onToggleDropdown={setMenuExpanded}
                    >
                      <DropdownActionIconButton
                        variant="for-white-background"
                        icon={<MoreFillIcon className="tw-h-6 tw-w-6 tw-rounded" />}
                        className={classNames(
                          "tw-rounded-6",
                          { "!tw-border-transparent": isSavingFolder },
                          "tw-mr-1" // prevent outline getting clipped by container
                        )}
                        size="24"
                        loading={isSavingFolder}
                        loadingIconClassName="tw-w-6 tw-h-6 tw-p-1"
                      />
                    </TeamspaceMenu>
                  ) : (
                    <PrivateFolderMenu
                      folder={folder}
                      onChange={loadFolders}
                      onSaving={setSavingFolder}
                      onToggleDropdown={setMenuExpanded}
                    >
                      <DropdownActionIconButton
                        variant="for-white-background"
                        icon={<MoreFillIcon />}
                        className={classNames("tw-rounded-6", {
                          "!tw-border-transparent": isSavingFolder
                        })}
                        size="24"
                        loading={isSavingFolder}
                        loadingIconClassName="tw-w-6 tw-h-6 tw-p-1"
                      />
                    </PrivateFolderMenu>
                  )}
                </div>
              )}
              {isUpdating && <LoadingIcon className="tw-h-3 tw-w-3" />}
            </div>
          </div>

          {(includeCreateFolderButton || includeUploadButton) && (
            <ul className="tw-ml-auto tw-flex tw-gap-2">
              {members && (
                <li className="tw-inline-flex tw-items-center">
                  <UserGroup
                    users={members}
                    canClick={canUpdateFolder}
                    onClick={() => folder?.groupId && handleOpenTeamspaceManager()}
                    currentUserId={user?.id}
                  />
                </li>
              )}
              {includeCreateFolderButton && (
                <li className="tw-flex tw-h-10 tw-items-center">
                  <IconButton
                    icon={<FolderAddLineIcon />}
                    variant="secondary"
                    raised
                    size="32"
                    onClick={handleCreateNewFolder}
                    loading={isCreatingFolder}
                    // className="tw-w-12 !tw-px-3"
                    disabled={!canCreateFolder || isCreatingFolder}
                  />
                </li>
              )}
              {includeUploadButton && (
                <li className="tw-flex tw-h-10 tw-items-center">
                  <DashboardUploadButton disabled={!canUploadMedia} />
                </li>
              )}
            </ul>
          )}
        </div>
        <div className="tw-flex tw-h-[46px] tw-flex-col tw-justify-between tw-gap-1">
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-col">
              {includeFileType && (
                <Tabs>
                  <Tabs.Tab
                    selected={!queryParams.fileType}
                    theme="primary"
                    onClick={() => updateQueryParams({ fileType: null })}
                  >
                    All
                  </Tabs.Tab>
                  <Tabs.Tab
                    selected={queryParams.fileType === FileType.Video}
                    onClick={() => updateQueryParams({ fileType: FileType.Video })}
                    theme="primary"
                  >
                    Videos
                  </Tabs.Tab>
                  <Tabs.Tab
                    selected={queryParams.fileType === FileType.Audio}
                    onClick={() => updateQueryParams({ fileType: FileType.Audio })}
                    theme="primary"
                  >
                    Audio
                  </Tabs.Tab>
                </Tabs>
              )}
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              {includeSearch && <DashboardMediaSearch size="xs" />}
              {includeMediaControls && (
                <ViewTypeDropdown
                  viewType={queryParams.viewType ?? "list"}
                  onChangeViewType={(viewType) => updateQueryParams({ viewType })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type BreadcrumbsProps = {
  crumbs: Folder[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
  const isLast = (crumbs: Folder[], index: number) => crumbs.length - 1 === index;

  return (
    <ul className="tw-flex tw-flex-wrap tw-items-center tw-text-sm tw-font-medium">
      {crumbs.map((crumb, i) => (
        <li
          key={i}
          className={classNames("tw-inline-block tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap", {
            "tw-text-neutral-500": !isLast(crumbs, i), // is first or middle
            "tw-text-neutral-900": isLast(crumbs, i)
          })}
        >
          <Link to={getFolderPath(crumb.id)}>{crumb.name}</Link>
          {!isLast(crumbs, i) && <span className="tw-text-neutral-500">&nbsp;/&nbsp;</span>}
        </li>
      ))}
    </ul>
  );
};
