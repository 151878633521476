import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { AppModal } from "@frontend/components/app-modal/app-modal";
import { useAppConnection } from "@frontend/contexts/app-connection.context";

import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useModal } from "@core/hooks/use-modal";
import { App } from "@core/interfaces/app";
import { ModalType } from "@core/interfaces/modal-type";

import { CategoryButton } from "../drives/category-button";
import { MenuItem } from "../sidebar-nav/sidebar-nav";

interface SourceItemProps {
  apps: App[];
}

const SourceItems: React.FC<SourceItemProps> = ({ apps }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();

  return (
    <ul>
      {apps
        .filter((a) => a.showOnSidebar && !a.hidden)
        .map((app) => {
          const renderIcon = () => {
            return React.cloneElement(app.icon, { className: "tw-h-5 tw-w-5 tw-shrink-0" });
          };

          return (
            <li key={app.appId}>
              <MenuItem
                icon={renderIcon()}
                text={app.name}
                onClick={() => {
                  trackEventWithAuth(`Sidebar / ${app.name}`);

                  if (app.connected) {
                    navigate(app.link);
                  } else {
                    showModal(<AppModal app={app} closeModal={hideModal} />);
                  }
                }}
              />
            </li>
          );
        })}
    </ul>
  );
};

export const SourcesNav: React.FC = () => {
  const [showSources, onToggleCategory] = React.useState(true);
  const { apps, isLoading, fetchAppList } = useAppConnection();

  React.useEffect(() => {
    fetchAppList();
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <CategoryButton description="Sources" onClick={() => onToggleCategory(!showSources)}>
        Sources
      </CategoryButton>
      {!isLoading && showSources && <SourceItems apps={apps} />}
      {isLoading && showSources && <Skeleton className="tw-h-4 tw-w-24" />}
    </div>
  );
};
