import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { updateSettings } from "@frontend/api/auth.service";
import { getMediaList } from "@frontend/api/media.service";
import { DashboardHeader } from "@frontend/containers/dashboard/dashboard-explorer/components/dashboard-header";
import { EmptyView } from "@frontend/containers/dashboard/dashboard-explorer/components/empty-view";
import { FolderNotFound } from "@frontend/containers/dashboard/dashboard-explorer/components/folder-not-found";
import { MediaView } from "@frontend/containers/dashboard/dashboard-explorer/components/media-view";
import { DashboardFeedback } from "@frontend/containers/dashboard/dashboard-feedback/dashboard-feedback";
import { DashboardUpload } from "@frontend/containers/dashboard/dashboard-upload/dashboard-upload";
import { HumanServicesAnnouncement } from "@frontend/containers/modals/human-services-announcement-modal";
import { SummaryServicesAnnouncement } from "@frontend/containers/modals/summary-service-announcement-modal";

import { useAccounts } from "@core/hooks/use-accounts";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { useQuery } from "@core/hooks/use-query";
import { ModalType } from "@core/interfaces/modal-type";
import { dashboardRepository, Folder, VirtualFolderId } from "@core/state/dashboard/dashboard.store";
import { RoleName } from "@getsubly/common";

// This function determines the folder details based on the selected folder ID.
const getFolderDetails = (
  selectedFolderId: string | VirtualFolderId | null,
  activeFolder: Folder | null | undefined,
  breadcrumbs: Folder[]
) => {
  let title = "";
  let folder;
  let crumbs = breadcrumbs ?? [];

  switch (selectedFolderId) {
    case VirtualFolderId.All:
      title = "All media";
      crumbs = [createCrumb({ name: "All media", id: VirtualFolderId.All })];
      break;
    case VirtualFolderId.AllSharedWithMe:
      title = "Shared with me";
      crumbs = [
        createCrumb({
          name: "Shared with me",
          id: VirtualFolderId.AllSharedWithMe
        })
      ];
      break;
    default:
      if (activeFolder) {
        title = activeFolder.name;
        folder = activeFolder;
      }
      break;
  }

  return { title, folder, crumbs };
};

// This function creates a breadcrumb object.
const createCrumb = (defaults: Partial<Folder>): Folder => ({
  name: "",
  id: "",
  depth: -1,
  order: -1,
  groupId: null,
  parentId: null,
  createdAt: "",
  updatedAt: "",
  children: [],
  ...defaults
});

const PATHNAME_TO_FOLDER_ID_MAP: Record<string, VirtualFolderId> = {
  "/all-media": VirtualFolderId.All,
  "/shared-with-me": VirtualFolderId.AllSharedWithMe
};

export const FolderPage = () => {
  const {
    selectedFolderId,
    activeFolder,
    hasLoadedFolderMedia,
    hasLoadedFolders,
    favouritesDrive,
    teamspacesDrive,
    privateDrive,
    media: mediaList,
    breadcrumbs,
    refreshMediaListKey
  } = useDashboard();

  const { user, currentAccount } = useAccounts();

  const isViewer = currentAccount?.role === RoleName.Viewer;
  const params = useParams();
  const { queryParams } = useQuery();
  const location = useLocation();
  const { selectFolder, setLastPath } = useDashboard();

  const { title, folder, crumbs } = getFolderDetails(selectedFolderId, activeFolder, breadcrumbs ?? []);
  const folders = activeFolder?.children ?? [];

  const fetchMedia = (selectedFolderId: string | VirtualFolderId | null) => {
    dashboardRepository.updateState({
      isLoadingFolderMedia: true,
      hasLoadedFolderMedia: false
    });
    const { cancel, response } = getMediaList(selectedFolderId, {
      limit: 20,
      type: queryParams.fileType,
      search: queryParams.q,
      sort: queryParams.sort,
      order: queryParams.order
    });
    response.finally(() => {
      dashboardRepository.updateState({
        isLoadingFolderMedia: false,
        hasLoadedFolderMedia: true
      });
    });
    return cancel;
  };

  const loadMoreMedia = async () => {
    dashboardRepository.updateState({ isLoadingFolderMedia: true });
    const { response } = getMediaList(selectedFolderId, {
      limit: 20,
      skip: mediaList.length,
      type: queryParams.fileType,
      search: queryParams.q,
      sort: queryParams.sort,
      order: queryParams.order
    });
    response.finally(() => dashboardRepository.updateState({ isLoadingFolderMedia: false }));
  };

  React.useEffect(() => {
    if (hasLoadedFolders) {
      let selectedFolderId: VirtualFolderId | string = VirtualFolderId.All;

      // if folderId exists, we're in a "real folder"
      if (params.folderId) {
        selectedFolderId = params.folderId;
      }
      // if pathname is known, we're in a "virtual folder"
      else if (PATHNAME_TO_FOLDER_ID_MAP[location.pathname]) {
        selectedFolderId = PATHNAME_TO_FOLDER_ID_MAP[location.pathname];
      }
      // if folder is undetermined (i.e., root URL), default to first visible sidebar folder
      else {
        // get first available drive
        const drives = [favouritesDrive, teamspacesDrive, privateDrive].filter((drive) => drive !== null) as Folder[];
        // get first folder in first drive
        const defaultFolder = drives?.find((drive) => drive?.children?.[0])?.children?.[0];
        // if no folder, default to "all media" virtual folder
        selectedFolderId = defaultFolder?.id ?? VirtualFolderId.All;
      }

      selectFolder(selectedFolderId);

      const cancel = fetchMedia(selectedFolderId);

      return () => {
        cancel?.();
        // Clear the store when the page unmounts to prevent residual state causing bugs.
        dashboardRepository.updateState({ media: [], isLoadingFolderMedia: true, hasLoadedFolderMedia: false });
      };
    }
    return () => {
      // Clear the store when the page unmounts to prevent residual state causing bugs.
      dashboardRepository.updateState({ media: [], isLoadingFolderMedia: true, hasLoadedFolderMedia: false });
    };
  }, [
    hasLoadedFolders,
    params.folderId,
    location.pathname,
    queryParams,
    queryParams.q,
    queryParams.sort,
    queryParams.order,
    refreshMediaListKey
  ]);

  React.useEffect(() => {
    setLastPath(window.location.pathname + window.location.search);
  }, [window.location.pathname, window.location.search]);

  const [showModal, hideModal] = useModal(ModalType.HumanServicesAnnouncementModal);
  const [showSummaryModal, hideSummaryModal] = useModal(ModalType.SummaryServicesAnnouncementModal);
  const loginCount = React.useMemo(() => user?.settings?.loginCount ?? 0, [user?.settings]);
  const hasViewedHumanServicesAnnouncement = React.useMemo(
    () => user?.settings?.hasViewedHumanServicesAnnouncement,
    [user?.settings]
  );
  const hasViewedSummaryServicesAnnouncement = React.useMemo(
    () => user?.settings?.hasViewedSummaryServicesAnnouncement,
    [user?.settings]
  );

  React.useEffect(() => {
    if (loginCount < 2 || hasViewedHumanServicesAnnouncement) return;

    const handleCancel = () => {
      hideModal();
      updateSettings({ ...(user?.settings ?? {}), hasViewedHumanServicesAnnouncement: true });
    };

    setTimeout(() => {
      showModal(<HumanServicesAnnouncement onCancel={handleCancel} />);
    }, 1500);
  }, []);

  React.useEffect(() => {
    if (hasViewedSummaryServicesAnnouncement || !(loginCount < 2 || hasViewedHumanServicesAnnouncement)) return;

    const handleCancel = () => {
      hideSummaryModal();
      updateSettings({ ...(user?.settings ?? {}), hasViewedSummaryServicesAnnouncement: true });
    };

    setTimeout(() => {
      showSummaryModal(<SummaryServicesAnnouncement onCancel={handleCancel} />);
    }, 1500);
  }, [hasViewedHumanServicesAnnouncement, loginCount]);

  const viewType = React.useMemo<"loader" | "empty-virtual-folder" | "empty-real-folder" | "explorer">(() => {
    // Display loading view until media has initially loaded.
    if (!hasLoadedFolderMedia) return "loader";

    // Check if folder is virtual and empty
    const isVirtualFolder = selectedFolderId === VirtualFolderId.All || selectedFolderId === VirtualFolderId.AllSharedWithMe; // eslint-disable-line
    const isFolderEmpty = !activeFolder?.children?.length && !mediaList.length;

    // Display empty view for empty "virtual folders"
    if (isVirtualFolder && isFolderEmpty) return "empty-virtual-folder";

    // Display upload view for empty "real folders"
    if (isFolderEmpty) return "empty-real-folder";

    // Display explorer by default
    return "explorer";
  }, [hasLoadedFolderMedia, selectedFolderId, activeFolder, mediaList]);

  // Wait for folder retrieval on initial render
  if (!hasLoadedFolders) return;

  const isVirtualFolder = selectedFolderId === VirtualFolderId.All || selectedFolderId === VirtualFolderId.AllSharedWithMe; // eslint-disable-line
  const isRealFolder = Boolean(folder);

  if (Boolean(selectedFolderId) && !isVirtualFolder && !isRealFolder) {
    return <FolderNotFound />;
  }

  return (
    <>
      <div className="tw-relative tw-flex tw-min-h-full tw-shrink-0 tw-flex-col tw-p-8">
        <DashboardHeader
          title={title}
          folder={folder}
          crumbs={crumbs}
          includeSearch
          includeCreateFolderButton
          includeMediaControls
          includeUploadButton
          includeFileType
          includeFolderOptions={!isViewer && (folder?.depth || 0) > 0}
        />
        <DashboardFeedback className="tw-mb-4" />
        {(() => {
          switch (viewType) {
            case "loader":
              return <LoaderView />;
            case "empty-virtual-folder":
              return <EmptyView />;
            case "empty-real-folder":
              return <DashboardUpload />;
            case "explorer":
              return <MediaView mediaList={mediaList} folders={folders} onLoadMoreMedia={loadMoreMedia} />;
            default:
              return "404";
          }
        })()}
      </div>
    </>
  );
};

const LoaderView = () => {
  return (
    <div className="tw-flex tw-min-h-[240px] tw-cursor-default tw-items-center tw-justify-center tw-space-y-5 tw-rounded-[8px] tw-border tw-border-neutral-200 tw-p-5">
      <div className="tw-h-12 tw-w-12 tw-animate-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-neutral-500 tw-border-t-transparent"></div>
    </div>
  );
};
